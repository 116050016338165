import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import PlayButton from '../../assets/play-button.svg';
import GatsbyImage from 'gatsby-image';

const YoutubeVideo = ({ poster, id, alt, title }) => {
  const [userHasPressedPlay, setUserHasPressedPlay] = useState(false);
  const [width, setWidth] = useState(800);
  const container = useRef();

  const updateWidth = () => {
    if (container.current) {
      setWidth(parseFloat(window.getComputedStyle(container.current).width));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(updateWidth, [container.current]);

  return (
    <div
      className="relative mt-12 md:mt-16 w-full rounded-4xl md:rounded-6xl shadow-glow-video overflow-hidden"
      ref={container}
    >
      {userHasPressedPlay ? (
        <iframe
          title={title}
          src={`https://www.youtube-nocookie.com/embed/${id}?autoplay=1&modestbranding=1&rel=0&fs=0`}
          width={width}
          height={(width / 16) * 9}
          frameBorder="0"
        ></iframe>
      ) : (
        <>
          <GatsbyImage
            className="my-auto flex-1"
            fluid={poster.childImageSharp.fluid}
            alt={alt}
          />
          <div className="absolute flex inset-0 w-full h-full items-center justify-center">
            <button
              type="button"
              className="play-btn"
              aria-label="Play video"
              onClick={() => setUserHasPressedPlay(true)}
            >
              <PlayButton />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

YoutubeVideo.propTypes = {
  poster: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object,
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default YoutubeVideo;
