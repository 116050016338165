import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import ScrollTrigger from 'gsap/ScrollTrigger';

import Layout from '../layout/layout';
import SEO from '../meta/seo';
import FeatureIcon from './featureIcon';
import StoreButtons from '../common/storeButtons';
import YoutubeVideo from '../common/youtubeVideo';

// import exploreVideo from '../../assets/video/placeholder.mp4';
import featuresChromaKey from '../../assets/video/features/chromakey.mp4';
import featuresSpeedRamp from '../../assets/video/features/speedramp.mp4';
import featuresMultiTrack from '../../assets/video/features/multitrack.mp4';
import ArrowRight from '../../assets/arrow-right.svg';
import gsap from 'gsap/gsap-core';

const Explore = () => {
  const {
    iconIntuitive,
    iconAdvanced,
    iconMusic,
    mockupPhone,
    videoPoster,
    renderedIcons,
    subtitlesAsset,
  } = useStaticQuery(graphql`
    {
      iconIntuitive: file(relativePath: { eq: "icons/icon-intuitive.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconAdvanced: file(relativePath: { eq: "icons/icon-advanced.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconMusic: file(relativePath: { eq: "icons/icon-music.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      videoPoster: file(
        relativePath: { eq: "video/explore-video-poster.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mockupPhone: file(relativePath: { eq: "mockup-overlay.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      renderedIcons: file(relativePath: { eq: "3d-icons.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      subtitlesAsset: file(relativePath: { eq: "speech-to-text.png" }) {
        childImageSharp {
          fluid(maxWidth: 480, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const videoRef = useRef();
  const speedRampTrigger = useRef();
  const multiTrackTrigger = useRef();
  const chromaKeyTrigger = useRef();

  const [scrollVideoUrl, setScrollVideoUrl] = useState(featuresSpeedRamp);

  const videoFade = (ref, url) => {
    const tl = gsap.timeline();
    tl.to(ref, {
      opacity: 0,
      duration: 0.15,
      ease: 'power2.out',
      onComplete: () => {
        setScrollVideoUrl(url);
        ref.load();
      },
    });
    tl.to(ref, {
      opacity: 1,
      duration: 0.25,
      ease: 'power2.inOut',
    });
  };

  useEffect(() => {
    if (!videoRef.current) return;

    const createScrollTrigger = (
      trigger,
      videoUrl,
      onEnter = true,
      onEnterBack = true
    ) =>
      ScrollTrigger.create({
        trigger,
        start: 'top 50%',
        end: 'bottom 50%',
        onEnter: () => {
          if (onEnter) {
            videoFade(videoRef.current, videoUrl);
          }
        },
        onEnterBack: () => {
          if (onEnterBack) {
            videoFade(videoRef.current, videoUrl);
          }
        },
      });

    const scrollTriggers = [
      createScrollTrigger(speedRampTrigger.current, featuresSpeedRamp, false),
      createScrollTrigger(multiTrackTrigger.current, featuresMultiTrack),
      createScrollTrigger(
        chromaKeyTrigger.current,
        featuresChromaKey,
        true,
        false
      ),
    ];

    return () =>
      scrollTriggers.forEach((scrollTrigger) => scrollTrigger.kill());
  }, [videoRef.current]);

  return (
    <Layout campaign="explore">
      <SEO
        title="A Stack Of Intuitive and Powerful Video Editing Features"
        description="Powerful editing tools made simple. Just tap to trim clips, add slow-motion effects, and overlay multiple clips to create beautiful videos you’ll love to share."
      />

      <section className="container space-around xl:py-32">
        <div className="text-center">
          <h1 className="B32 sm:B48 md:B64 xl:B80 mb-4 md:mb-6">
            The all-in-one
            <br />
            <span className="text-gradient bg-splice-gradient-horizontal">
              mobile video editor.
            </span>
          </h1>
          <p className="text-short-lead max-w-content mx-auto">
            Why is Splice so popular with influencers? Because it&#39;s got a
            stack of intuitive features that help make videos the world wants to
            see.
          </p>

          {/* Store Buttons */}
          <StoreButtons
            campaign="explore-header"
            className="mt-6 md:mt-10 lg:mt-12 items-center"
          />

          <YoutubeVideo
            poster={videoPoster}
            id="9ELBjE7d5FU"
            alt="Splice features video placeholder"
            title="Splice features"
          />
        </div>
      </section>
      <section className="container space-around xl:py-32">
        <div className="text-center">
          <h2 className="text-title mb-12 md:mb-16">
            Why edit videos with Splice?
          </h2>
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <FeatureIcon
              className="text-accent-pink"
              highlight="Intuitive"
              text="user experience"
              icon={iconIntuitive}
            />
            <FeatureIcon
              className="text-accent-purple"
              highlight="Advanced"
              text="pro-level tools"
              icon={iconAdvanced}
            />
            <FeatureIcon
              className="text-accent-cyan"
              highlight="Enormous"
              text="music library"
              icon={iconMusic}
            />
          </div>
        </div>
      </section>

      <section className="container space-around xl:py-32 flex justify-between">
        <div className="w-full lg:max-w-half-column">
          <div className="sticky-feature" ref={speedRampTrigger}>
            <div className="sticky-feature__mockup">
              <div className="mockup">
                {/* Video Feature */}
                <video
                  className="mockup__video"
                  muted
                  autoPlay
                  loop
                  playsInline
                >
                  <source src={featuresSpeedRamp} type="video/mp4" />
                </video>
                {/* Video Overaly Image */}
                <div className="mockup__frame">
                  <GatsbyImage
                    fluid={mockupPhone.childImageSharp.fluid}
                    alt="Phone Mockup"
                  />
                </div>
              </div>
            </div>
            <div className="sticky-feature__copy">
              <h2 className="text-title">Introducing Speed Ramp.</h2>
              <p className="mb-10">
                Pick up the pace or slow things down when the action gets good.
                Because, in your videos, why do the laws of space and time need
                to apply?
              </p>
              {/* TODO: Revert back with tutorials once ready */}
              <StoreButtons
                campaign="explore-feature"
                className="mt-6 md:mt-10 lg:mt-12 items-center"
              />
              {/* <Link to="/tutorials/" className="btn btn--blue">
                <div>Follow our tutorial</div>
                <ArrowRight />
              </Link> */}
            </div>
          </div>

          <div className="sticky-feature" ref={multiTrackTrigger}>
            <div className="sticky-feature__mockup">
              <div className="mockup">
                {/* Video Feature */}
                <video
                  className="mockup__video"
                  muted
                  autoPlay
                  loop
                  playsInline
                >
                  <source src={featuresMultiTrack} type="video/mp4" />
                </video>
                {/* Video Overaly Image */}
                <div className="mockup__frame">
                  <GatsbyImage
                    fluid={mockupPhone.childImageSharp.fluid}
                    alt="Phone Mockup"
                  />
                </div>
              </div>
            </div>
            <div className="sticky-feature__copy">
              <h2 className="text-title">Keep projects on track.</h2>
              <p className="mb-10">
                No need to pick which projects to keep and which to delete. Have
                as many on the go as you want, thanks to Splice’s advanced
                editor workflow.
              </p>
              {/* TODO: Revert back with tutorials once ready */}
              <StoreButtons
                campaign="explore-feature"
                className="mt-6 md:mt-10 lg:mt-12 items-center"
              />
              {/* <Link to="/tutorials/" className="btn btn--blue">
                <div>Follow our tutorial</div>
                <ArrowRight />
              </Link> */}
            </div>
          </div>

          <div className="sticky-feature" ref={chromaKeyTrigger}>
            <div className="sticky-feature__mockup">
              <div className="mockup">
                {/* Video Feature */}
                <video
                  className="mockup__video"
                  muted
                  autoPlay
                  loop
                  playsInline
                >
                  <source src={featuresChromaKey} type="video/mp4" />
                </video>
                {/* Video Overaly Image */}
                <div className="mockup__frame">
                  <GatsbyImage
                    fluid={mockupPhone.childImageSharp.fluid}
                    alt="Phone Mockup"
                  />
                </div>
              </div>
            </div>
            <div className="sticky-feature__copy">
              <h2 className="text-title">
                Blue sneakers? <i>Really?!</i>
              </h2>
              <p className="mb-10">
                Luckily, with chroma key, you can change the color in just a
                tap! (Pro tip: Changing them to yellow isn&apos;t any better...)
              </p>
              {/* TODO: Revert back with tutorials once ready */}
              <StoreButtons
                campaign="explore-feature"
                className="mt-6 md:mt-10 lg:mt-12 items-center"
              />

              {/* <Link to="/tutorials/" className="btn btn--blue">
                <div>Follow our tutorial</div>
                <ArrowRight />
              </Link> */}
            </div>
          </div>
        </div>

        <div className="hidden lg:flex sticky top-8 w-full max-w-half-column h-screen">
          <div className="mockup">
            {/* Video Feature */}
            <video
              ref={videoRef}
              className="mockup__video"
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={scrollVideoUrl} type="video/mp4" />
            </video>
            {/* Video Overaly Image */}
            <div className="mockup__frame">
              <GatsbyImage
                fluid={mockupPhone.childImageSharp.fluid}
                alt="Phone Mockup"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-black bg-pink-spotlight">
        <section className="container space-around xl:py-32">
          <div className="text-and-image text-and-image--reverse">
            <div className="text-and-image__image">
              <GatsbyImage
                fluid={renderedIcons.childImageSharp.fluid}
                alt="Splice toolbar's icons"
              />
            </div>
            <div className="text-and-image__text text-and-image__text--wide text-center lg:text-left">
              <h2 className="text-title mb-6">
                And many more
                <br />
                <span className="text-gradient bg-splice-gradient-horizontal">
                  coming soon!
                </span>
              </h2>
              <p className="text-long-lead lg:mr-14 mb-10">
                Just as the Splice community keeps evolving, so does our app.
                We’re forever adding new features and ideas from our community
                members.
              </p>
              <a
                href="https://support.spliceapp.com/hc/en-gb/requests/new"
                className="btn btn--blue"
              >
                <div>Suggest a feature</div>
                <ArrowRight />
              </a>
            </div>
          </div>
        </section>
      </div>

      <section className="container space-around xl:py-32">
        <div className="text-and-image">
          <GatsbyImage
            className="text-and-image__image"
            fluid={subtitlesAsset.childImageSharp.fluid}
            alt="Accessible by everyone"
          />
          <div className="text-and-image__text text-center lg:text-left">
            <h4 className="tag tag-pineapple mb-4">coming soon</h4>
            <h2 className="text-title mb-4 md:mb-6">Automatic subtitles.</h2>
            <p>
              Having subtitles is cool right? What about having the subtitles
              generate themselves? Thats right, Splice is going to do that for
              you, very soon.
            </p>
          </div>
        </div>
      </section>

      {/* <!-- Start of spliceapp Zendesk Widget script --> */}
      <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=a75f26d1-dd53-431e-8e61-e3ae89b7bad6"> </script>
      {/* <!-- End of spliceapp Zendesk Widget script --> */}
    </Layout>
  );
};

export default Explore;
