import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';

const FeatureIcon = ({ icon, highlight, text, className, ...rest }) => (
  <div className="mb-16 lg:mb-0 w-full max-w-third-column" {...rest}>
    {/* Icon */}
    <GatsbyImage
      className="mb-2 mx-auto w-48 md:w-56 xl:w-full"
      fluid={icon.childImageSharp.fluid}
      alt={`${highlight}`}
    />
    {/* Text */}
    <h4 className="B28 md:B32 xl:B40 text-white">
      <span className={className}>{highlight}</span>
      <br />
      {text}
    </h4>
  </div>
);

FeatureIcon.propTypes = {
  icon: PropTypes.object,
  highlight: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default FeatureIcon;
